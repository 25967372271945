<template>
  <div v-if="isAuthorized" class="d-flex align-items-center user-info">
    <template v-if="!isMyProfileLoading">
      <Avatar size="36" class="h-mr-2" type="user" :img-url="avatar" />
      <span class="h-mr-3 font-size-base user-info_name">{{ myName }}</span>
    </template>
    <router-link :to="{ name: 'signout' }" class="font-size-base">
      Log out
    </router-link>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { Avatar } from 'playhera-components';

export default {
  name: 'Navbar',
  components: {
    Avatar,
  },
  computed: {
    ...mapGetters({ myName: 'myProfile/name' }),
    ...mapGetters(['isAuthorized']),
    ...mapState({
      isMyProfileLoading: (state) => state.myProfile.isLoading,
      avatar: (state) => state.myProfile.data.avatar,
    }),
  },
};
</script>

<style lang="scss">
.user-info {
  &_name {
    color: $strokes-color;
  }
}
</style>

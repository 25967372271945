<template>
  <span
    class="playhera-icon"
    @mouseenter.self="isHovered = true"
    @mouseleave.self="isHovered = false"
    v-on="$listeners"
  >
    <svg
      v-bind="{ viewBox, width: normalizedWidth }"
      :style="{ '--fill': displayFill, '--rotate': `${rotate}deg` }"
    >
      <use :href="`#${displayName}`" />
    </svg>
  </span>
</template>

<script>
import sprite from 'svg-sprite-loader/runtime/browser-sprite.build';

export default {
  name: 'AppIcon',
  props: {
    name: {
      type: String,
      required: true,
    },
    fill: {
      type: null,
      default: '',
    },
    width: {
      type: [String, Number],
      default: '18',
    },
    rotate: {
      type: Number,
      default: 0,
    },
    original: {
      type: Boolean,
      default: false,
    },
    hoverName: {
      type: String,
      default: '',
    },
    hoverFill: {
      type: null,
      default: '',
    },
  },
  data() {
    return {
      isHovered: false,
    };
  },
  computed: {
    displayFill() {
      return (this.isHovered && this.hoverFill) || this.fill;
    },
    displayName() {
      return (
        ((this.isHovered && this.hoverName) || this.name) +
        (this.original ? '--original' : '')
      );
    },
    normalizedWidth() {
      return `${parseInt(this.width)}px`;
    },
    viewBox() {
      return sprite.symbols.find(({ id }) => id === this.displayName).viewBox;
    },
  },
};
</script>

<style lang="scss">
.playhera-icon {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;

  svg {
    --fill: #{$ph-secondary-text};
    --rotate: 0;

    transform: rotate(var(--rotate));
    use {
      fill: var(--fill);
      stroke: none;
    }
    path {
      fill: inherit;
    }
  }
}
</style>

<template>
  <div
    class="accordion-menu-item"
    :class="{
      'accordion-menu-item--has-children': hasChildren,
      'accordion-menu-item--no-children': !hasChildren,
    }"
  >
    <template v-if="hasChildren">
      <div
        class="parent-link cursor-hand"
        :class="[itemClass]"
        @click="toggleItem"
      >
        <Icon
          name="arrow-b"
          class="mx-2"
          width="14"
          :rotate="isOpen ? 0 : -90"
        />
        <Icon :name="route.icon" class="mr-3" width="22" />{{ route.label }}
      </div>
      <transition name="shrink">
        <div v-show="isOpen" class="accordion-menu-item__children">
          <router-link
            v-for="(item, index) in route.children"
            :key="`${item.name}${index}`"
            :disabled="item.disabled"
            :to="{ name: item.name, params: item.params }"
            class="d-flex align-items-center position-relative"
            :class="[itemClass, { disabled: item.disabled }]"
          >
            <TextBlock :size="0.875" variant="primary">{{
              item.label
            }}</TextBlock>
          </router-link>
        </div>
      </transition>
    </template>
    <router-link
      v-else
      :to="{ name: route.name, params: route.params }"
      :disabled="route.disabled"
      class="d-flex align-items-center"
      :class="[itemClass]"
    >
      <TextBlock :size="0.875" variant="primary">
        <Icon :name="route.icon" class="mr-3" />{{ route.label }}</TextBlock
      >
    </router-link>
  </div>
</template>

<script>
import Icon from 'playhera-icons/Icon';
import TextBlock from '@/components/text/TextBlock';
export default {
  name: 'AccordionMenuItem',
  components: {
    TextBlock,
    Icon,
  },
  props: {
    route: {
      type: Object,
      required: true,
    },
    itemClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    hasChildren() {
      return Boolean(this.route.children);
    },
  },
  methods: {
    toggleItem() {
      this.isOpen = !this.isOpen;
      this.$emit('toggle', this.isOpen);
    },
  },
};
</script>

<style lang="scss">
.accordion-menu-item {
  &--no-children {
    .sub-menu__link {
      padding-left: 2.125rem !important;
    }
  }
  &--has-children {
    .parent-link {
      color: $text-primary-color !important;
    }
    .accordion-menu-item__children {
      .sub-menu__link {
        padding-left: 4.25rem !important;
        &.disabled {
          pointer-events: none;
          opacity: 0.6;
        }
        &.router-link-exact-active {
          color: $primary-text-color;
          &::before {
            content: '';
            width: 3px;
            height: 100%;
            background: $primary;
            position: absolute;
            top: 0;
            left: -3px;
          }
        }
      }
    }
  }
}
</style>

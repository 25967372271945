<template>
  <div
    class="container-fluid p-0 font-size-xs layout-wrapper admin-layout-wrapper"
    :class="[$store.getters.localeDirection]"
  >
    <div class="d-flex flex-column h-100">
      <div class="d-flex flex-grow-1 layout-content-wrapper">
        <div class="flex-grow-1 admin-content-wrapper d-flex flex-column">
          <Navbar>
            <UserInfo />
          </Navbar>
          <div class="main-content d-flex flex-column flex-grow-1 pt-2">
            <div class="flex-grow-1 py-3 row no-gutters flex-nowrap">
              <div class="left-sidebar-wrapper col-auto position-relative">
                <AccordionMenu :routes="$options.adminRoutes" />
              </div>
              <div class="center-content-wrapper col">
                <slot />
              </div>
            </div>
          </div>
          <TextBlock
            class="pb-3 text-center"
            :size="0.875"
            variant="muted"
            tag="div"
            >PLAYHERA CORE PORTAL</TextBlock
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Navbar from '@/components/Navbar';
import TextBlock from '@/components/text/TextBlock';
import AccordionMenu from '@/components/menu/AccordionMenu';
import UserInfo from './UserInfo';

export default {
  name: 'AdminLayout',
  components: {
    Navbar,
    TextBlock,
    AccordionMenu,
    UserInfo,
  },
  computed: {
    isCurrentRoute() {
      return this.$route.name === this.$router.currentRoute.name;
    },
  },
  created() {
    this.fetchMyProfile();
    this.$options.adminRoutes = [
      {
        name: 'dashboard',
        label: 'Dashboard',
        icon: 'globe',
      },
      {
        label: 'Reports',
        icon: 'write',
        children: [
          {
            name: 'report1',
            label: 'Users',
            disabled: true,
          },
          {
            name: 'report2',
            label: 'Tournaments',
            disabled: true,
          },
          {
            name: 'report3',
            label: 'Games',
            disabled: true,
          },
          {
            name: 'report4',
            label: 'Genre',
            disabled: true,
          },
          {
            name: 'report5',
            label: 'Matches / Battles',
            disabled: true,
          },
          {
            name: 'report6',
            label: 'Teams',
            disabled: true,
          },
          {
            name: 'report7',
            label: 'Prize winnings',
            disabled: true,
          },
          {
            name: 'report8',
            label: 'Leagues',
            disabled: true,
          },
          {
            name: 'report9',
            label: 'Customer Support',
            disabled: true,
          },
        ],
      },
    ];
  },
  methods: {
    ...mapActions({ fetchMyProfile: 'myProfile/fetch' }),
  },
};
</script>

<style lang="scss">
$transition: 0.3s linear;
.layout-content-wrapper {
  background-color: $page-bg-color;
  max-width: 100vw;
}
.admin-content-wrapper {
  flex-basis: 100%;
  max-width: 100%;
}

.main-content {
  position: relative;
  z-index: 2;
  & > div {
    padding-left: 4.875rem;
    padding-right: 4.875rem;
    .left-sidebar-wrapper {
      min-width: 250px;
      .sub-menu__link {
        &.router-link-exact-active {
          background: $hover-color;
          border-top-right-radius: 2rem;
          border-bottom-right-radius: 2rem;
        }
      }
    }
  }
}
.admin-layout-wrapper {
  max-width: 1920px;
  min-height: 100vh;
  & > div {
    min-height: 100vh;
  }
}
</style>

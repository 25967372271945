<template>
  <ul
    class="sub-menu list-group p-0"
    :class="[`sub-menu--${variant}`, { sticky: isSticky }]"
  >
    <li
      v-for="(item, index) in items"
      :key="`sub-menu-item-${index}`"
      class="list-group__item list-group-item bg-transparent border-0 p-0"
    >
      <slot v-bind="{ item, defaultClass: 'sub-menu__link' }">
        {{ item }}
      </slot>
    </li>
  </ul>
</template>

<script>
const SUBMENU_VARIANTS = ['default', 'horizontal'];
// TODO - TH-7092 change slot markup
// https://router.vuejs.org/api/#applying-active-class-to-outer-element
//
export default {
  name: 'SubMenu',
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    isSticky: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'default',
      validator(val) {
        return SUBMENU_VARIANTS.includes(val);
      },
    },
  },
  mounted() {
    if (this.isSticky) {
      const $navbar = document.getElementById('nav');
      if ($navbar) {
        this.$el.style.top = `${$navbar.clientHeight}px`;
      }
    }
  },
};
</script>
<style lang="scss">
.sub-menu {
  border-left: 3px solid $element-bg-color;
  .sub-menu__link {
    transition: color 0.3s ease-in;
    padding: 0.75rem 0px 0.75rem 1.25rem;
    font-weight: bold;
    font-size: 0.875rem;
    text-decoration: none;
    color: $secondary-text-color;
    &.router-link-exact-active {
      color: $primary-text-color;
      &::before {
        content: '';
        width: 3px;
        height: 100%;
        background: $primary;
        position: absolute;
        top: 0;
        left: -3px;
      }
    }
  }
  .list-group__item {
    position: relative;
  }
  &.sticky {
    position: sticky;
  }
  &--horizontal {
    flex-direction: row;
    border-left: 0;
    .sub-menu__link {
      will-change: border-color;
      transition: border-color 0.3s ease-in;
      padding: 0.75rem 0.5rem;
      border-bottom: 3px solid transparent;
      &.router-link-exact-active {
        border-color: $primary;
        &::before {
          display: none;
        }
      }
    }
  }
}
.rtl {
  .sub-menu.list-group {
    border-left: none;
    border-right: 3px solid $element-bg-color;
    .sub-menu__link {
      padding: 0.75rem 1.25rem 0.75rem 0;
      &.router-link-exact-active {
        &::before {
          content: '';
          width: 3px;
          height: 100%;
          background: $primary;
          position: absolute;
          top: 0;
          left: unset;
          right: -3px;
        }
      }
    }
  }
}
</style>

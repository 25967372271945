<template>
  <SubMenu
    :items="routes"
    class="h-pr-3 sticky-top accordion-menu"
    style="top: 1rem"
  >
    <template slot-scope="{ item, defaultClass }">
      <AccordionMenuItem
        :route="item"
        class="accordion-menu__item"
        :item-class="defaultClass"
      />
    </template>
  </SubMenu>
</template>

<script>
import AccordionMenuItem from './AccordionMenuItem';
import SubMenu from '@/components/common/SubMenu';
export default {
  name: 'AccordionMenu',
  components: {
    AccordionMenuItem,
    SubMenu,
  },
  props: {
    routes: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.accordion-menu {
  .accordion-menu__item {
    .sub-menu__link {
      padding-left: 0;
    }
  }
}
</style>
